// UserSearchInput.js

import React, {useState, useCallback, useRef, useEffect} from 'react';
import {TextField} from '@mui/material';
import './UserSearchInput.css';

const UserSearchInput = ({availableUsers, onUserSelect}) => {
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const inputRef = useRef(null);

    const filterUsers = useCallback((searchTerm) => {
        const lowerCaseSearch = searchTerm.toLowerCase();

        return availableUsers
            .map(user => ({
                ...user,
                score: calculateScore(user, lowerCaseSearch)
            }))
            .filter(user => user.score > 0)
            .sort((a, b) => b.score - a.score)
            .slice(0, 10); // Limit to top 10 results
    }, [availableUsers]);

    const calculateScore = (user, searchTerm) => {
        const nameLower = user.name.toLowerCase();
        const emailLower = user.email.toLowerCase();

        if (nameLower.startsWith(searchTerm)) return 100;
        if (emailLower.startsWith(searchTerm)) return 90;
        if (nameLower.includes(searchTerm)) return 80;
        if (emailLower.includes(searchTerm)) return 70;

        return 0;
    };

    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
        if (value.length > 0) {
            setSuggestions(filterUsers(value));
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (user) => {
        setInputValue('');
        setSuggestions([]);
        onUserSelect(user);
    };

    const handleClickOutside = useCallback((event) => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
            setInputValue('');
            setSuggestions([]);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <div className="user-search-root" ref={inputRef}>
            <TextField
                fullWidth
                variant="outlined"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Enter name or email"
            />
            {suggestions.length > 0 && (
                <div className="suggestions-container">
                    {suggestions.map((user) => (
                        <div
                            key={user.id}
                            className="suggestion-item"
                            onClick={() => handleSuggestionClick(user)}
                        >
                            <div className="suggestion-avatar">
                                {user.name.charAt(0)}
                            </div>
                            <div className="suggestion-text">
                                <span className="suggestion-name">{user.name}</span>
                                <span className="suggestion-email">{user.email}</span>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default UserSearchInput;