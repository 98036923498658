import React from 'react';
import { Card, CardContent, Typography, IconButton, Box, Divider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import './css/TeamCard.css';

const TeamCard = ({ team, onClick, onDeleteClick }) => {
    const handleDeleteClick = (e) => {
        e.stopPropagation();
        onDeleteClick();
    };

    return (
        <Card className="team-card" onClick={onClick}>
            <CardContent>
                <Box className="team-card-header">
                    <Typography variant="h6" component="div">
                        {team.name}
                    </Typography>
                    <IconButton onClick={handleDeleteClick} size="small">
                        <DeleteIcon />
                    </IconButton>
                </Box>
                <Typography variant="body2" color="text.secondary">
                    {team.memberCount} users
                </Typography>
                <Divider className="team-card-divider" />
                <Typography variant="body2" className="team-card-manager-label">
                    Manager
                </Typography>
                <Typography variant="body1">
                    {team.managerName}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default TeamCard;