import config from '../config';

const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || '';

/**
 * Initialize Google Analytics with environment tracking
 */
export const initializeGA = () => {
    if (!GA_MEASUREMENT_ID) {
        console.warn('Google Analytics Measurement ID is not configured');
        return;
    }

    // Load GA Script
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
    document.head.appendChild(script);

    // Initialize dataLayer
    window.dataLayer = window.dataLayer || [];

    function gtag() {
        window.dataLayer.push(arguments);
    }

    gtag('js', new Date());
    // Configure GA with environment
    gtag('config', GA_MEASUREMENT_ID, {
        anonymize_ip: false,              // Keep location tracking
        allow_google_signals: false,      // Disable ad features
        allow_ad_personalization_signals: false,
        restricted_data_processing: false, // maybe it should be true, let's see
        environment: config.environment,
        debug_mode: config.environment !== 'prd'
    });

    window.gtag = gtag;
};

/**
 * Track page views with environment context
 */
export const trackPageView = (path, title) => {
    if (!window.gtag) return;

    window.gtag('event', 'page_view', {
        page_path: path,
        page_title: title,
        environment: config.environment
    });
};

/**
 * Track custom events with environment context
 */
// Track important user events
export const trackEvent = (category, action, label = null, value = null) => {
    if (!window.gtag) return;
    // console.log(action, label)
    window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value,
        environment: config.environment
    });
};

// Predefined event trackers for common actions
export const trackAuth = {
    signIn: (method) => trackEvent('Authentication', 'sign_in', method),
    signOut: () => trackEvent('Authentication', 'sign_out'),
    error: (error) => trackEvent('Authentication', 'error', error)
};

export const trackProduct = {
    featureUse: (feature) => trackEvent('Product', 'feature_use', feature),
    error: (error) => trackEvent('Product', 'error', error),
    success: (action) => trackEvent('Product', 'success', action)
};

export const trackInteraction = {
    buttonClick: (buttonName) => trackEvent('Interaction', 'button_click', buttonName),
    formSubmit: (formName) => trackEvent('Interaction', 'form_submit', formName),
    download: (fileName) => trackEvent('Interaction', 'download', fileName)
};