import React, {useRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './CustomTooltip.css';

const CustomTooltip = ({active, payload, label, onClick, valueFormatter, coordinate}) => {
    const [position, setPosition] = useState('center');
    const tooltipRef = useRef(null);
    const chartRef = useRef(null);

    useEffect(() => {
        // Find the chart container
        const chartContainer = document.querySelector('.recharts-wrapper');
        if (chartContainer) {
            chartRef.current = chartContainer;
        }
    }, []);

    useEffect(() => {
        if (active && payload && payload.length && coordinate && chartRef.current && tooltipRef.current) {
            const chartWidth = chartRef.current.offsetWidth;
            const tooltipWidth = tooltipRef.current.offsetWidth;
            const halfTooltipWidth = tooltipWidth / 2;

            if (coordinate.x - halfTooltipWidth < 0) {
                setPosition('left');
            } else if (coordinate.x + halfTooltipWidth > chartWidth) {
                setPosition('right');
            } else {
                setPosition('center');
            }
        }
    }, [active, payload, coordinate]);

    if (active && payload && payload.length) {
        const meetingData = payload[0].payload;

        const handleClick = (e) => {
            e.stopPropagation();
            onClick && onClick(meetingData);
        };

        const style = coordinate ? {
            left: (() => {
                switch (position) {
                    case 'left':
                        return coordinate.x + 10;
                    case 'right':
                        return coordinate.x - 10;
                    default: // center
                        return coordinate.x;
                }
            })(),
            top: coordinate.y,
            transform: (() => {
                switch (position) {
                    case 'left':
                        return 'translateY(-50%)';
                    case 'right':
                        return 'translate(-100%, -50%)';
                    default: // center
                        return 'translate(-50%, -50%)';
                }
            })()
        } : {};

        return (
            <div
                ref={tooltipRef}
                className={`custom-tooltip ${position}`}
                style={style}
                onClick={handleClick}
            >
                <p className="label">{label}</p>
                <p className="meeting-name">{meetingData.meta.meetingName}</p>
                {payload.map((entry, index) => (
                    <p key={`item-${index}`} style={{color: entry.color, marginBottom: '2px'}}>
                        {`${entry.name}: ${valueFormatter ? valueFormatter(entry.value) : entry.value}`}
                    </p>
                ))}
                <p className="click-info">Click to open meeting in new tab</p>
            </div>
        );
    }

    return null;
};

CustomTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.array,
    label: PropTypes.string,
    onClick: PropTypes.func,
    valueFormatter: PropTypes.func,
    coordinate: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number
    })
};

export default CustomTooltip;