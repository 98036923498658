import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';

export const useUserSelection = ({
    fetchUrl,
    initialSelections = {},
    maxSelections = {},  // { roleName: number | null }
    filterRules = {}     // { roleName: (user, selections) => boolean }
}) => {
    const [availableUsers, setAvailableUsers] = useState([]);
    const [selections, setSelections] = useState(initialSelections);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const fetchUsers = useCallback(async () => {
        if (availableUsers.length === 0) {
            try {
                setLoading(true);
                const response = await axios.get(fetchUrl);
                const processedUsers = response.data.map(user => ({
                    id: user.id || user.userId,
                    name: user.name || `${user.firstName} ${user.lastName}`.trim(),
                    email: user.email,
                    // Add any other needed fields
                }));
                setAvailableUsers(processedUsers);
            } catch (error) {
                console.error('Error fetching users:', error);
                setError('Failed to fetch users. Please try again.');
            } finally {
                setLoading(false);
            }
        }
    }, [fetchUrl, availableUsers.length]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const handleSelect = useCallback((role, user) => {
        if (user) {
            setSelections(prev => ({
                ...prev,
                [role]: user
            }));
        }
    }, []);

    const handleRemove = useCallback((role) => {
        setSelections(prev => {
            const newSelections = { ...prev };
            delete newSelections[role];
            return newSelections;
        });
    }, []);

    const getFilteredUsers = useCallback((filterKey) => {
        const filterRule = filterRules[filterKey] || filterRules.default;
        if (!filterRule) return availableUsers;

        return availableUsers.filter(user => filterRule(user, selections));
    }, [availableUsers, selections, filterRules]);

    return {
        availableUsers,
        selections,
        setSelections,
        loading,
        error,
        handleSelect,
        handleRemove,
        getFilteredUsers
    };
};