import React from 'react';
import { Box, Typography } from '@mui/material';
import UserSearchInput from './UserSearchInput';
import UserChip from "./UserChip";  // Your existing component

export const UserSelectionInput = ({
    role, // is the unique identifier of the UserSelectionInput
    label,
    availableUsers,
    onSelect,
    selectedUser = null,
    onRemove,
    placeholder
}) => (
    <Box className="user-selection-container">
        {label && (
            <Typography variant="subtitle1" className="input-label">
                {label}
            </Typography>
        )}

        {selectedUser ? (
            <UserChip
                user={selectedUser}
                onDelete={() => onRemove(role, selectedUser.id)}
            />
        ) : (
            <UserSearchInput
                availableUsers={availableUsers}
                onUserSelect={(user) => onSelect(role, user)}  // Pass both role and user directly
                placeholder={placeholder}
            />
        )}
    </Box>
);

export const UserMultiSelectionInput = ({
    role,
    label,
    availableUsers,
    selectedUsers = [],
    onSelect,
    onRemove
}) => (
    <Box className="user-selection-container">
        {label && (
            <Typography variant="subtitle1" className="input-label">
                {label} ({selectedUsers.length})
            </Typography>
        )}

        <Box className="chip-container">
            {selectedUsers.map((user) => (
                <UserChip
                    key={user.id}
                    user={user}
                    onDelete={() => onRemove(role, user.id)}
                />
            ))}
        </Box>

        <UserSearchInput
            availableUsers={availableUsers}
            onUserSelect={(user) => onSelect(role, user)}
        />
    </Box>
);
