import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import SignIn from "./auth/signIn";
import Product from "./product/Product";
import {BrowserRouter as Router, Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {useAuth, AuthProvider} from './auth/AuthProvider';
import axios from "axios";
import AuthCallback from "./auth/AuthCallback";
import LandingPage from "./landingpage/LandingPage";
import PrivacyPolicy from "./landingpage/PrivacyPolicy";
import About from "./landingpage/footer/About";
import Contact from "./landingpage/footer/Contact";
import Terms from "./landingpage/footer/Terms";
import {initializeGA, trackPageView} from './common/googleanalytics';
import config from './config';

axios.defaults.withCredentials = true;

// Initialize GA
initializeGA();

// Create a RouteTracker component to handle page view tracking
const RouteTracker = () => {
    const location = useLocation();

    React.useEffect(() => {
        trackPageView(location.pathname, document.title);
    }, [location]);

    return null;
};

const ProtectedRoute = ({children}) => {
    const {user} = useAuth();

    if (!user || !user.isAuthenticated) {
        return <Navigate to="/" replace/>;
    }
    return children;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <AuthProvider>
            <Router>
                <RouteTracker/>
                <Routes>
                    <Route exact path="/" element={<LandingPage/>}/>
                    <Route exact path="/privacy" element={<PrivacyPolicy/>}/>
                    <Route exact path="/about" element={<About/>}/>
                    <Route exact path="/contact" element={<Contact/>}/>
                    <Route exact path="/terms" element={<Terms/>}/>
                    <Route path="/auth/callback" element={<AuthCallback/>}/>
                    <Route path="/product/*" element={<ProtectedRoute><Product/></ProtectedRoute>}/>
                    <Route path="/sign-in-3b9fAll7c5aY0u8d1eN33d4e6a8iS7d5b9cL0V36a7d8e9b3" element={<SignIn/>}/>
                    {/*<Route path="/sign-in" element={<SignIn/>}/>*/}
                    {/*<Route path="/demo" element={<ProtectedRoute><DemoPage/></ProtectedRoute>}/>*/}
                    {/*<Route path="/about" component={AboutPage}/>*/}
                    {/* Add more routes as needed */}
                </Routes>
            </Router>
        </AuthProvider>
    </React.StrictMode>
);

const sendToAnalytics = ({id, name, value}) => {
    window.gtag?.('event', 'web_vitals', {
        metric_id: id,
        metric_name: name,
        metric_value: Math.round(value),
        environment: config.environment
    });
};

reportWebVitals(sendToAnalytics);
