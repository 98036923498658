import React from 'react';
import { Box, Typography, LinearProgress, Grid } from '@mui/material';
import './css/AnalyticsIndicators.css';

const IndicatorBar = ({ name, score }) => (
    <div className="indicator-bar">
        <Typography variant="body2">{name}</Typography>
        <div className="indicator-progress">
            {score !== null && score !== undefined ? (
                <>
                    <LinearProgress variant="determinate" value={score} />
                    <Typography variant="body2" className="indicator-score">
                        {`${score}/100`}
                    </Typography>
                </>
            ) : (
                <Typography variant="body2" className="indicator-no-data">
                    No data
                </Typography>
            )}
        </div>
    </div>
);

const AnalyticsIndicators = ({ data }) => {
    const indicators = [
        { name: 'Feedback', scoreKey: 'feedback.score' },
        { name: 'Questions', scoreKey: 'questions.score' },
        { name: 'Openness', scoreKey: 'openness.score' },
        { name: 'Supportiveness', scoreKey: 'supportiveness.score' },
        { name: 'Leadership', scoreKey: 'leadership.score' },
        { name: 'Trust', scoreKey: 'trust.score' },
        { name: 'Reflection', scoreKey: 'reflection.score' },
        { name: 'Blame', scoreKey: 'blame.score' },
        { name: 'Meeting Type', scoreKey: 'meetingType.Score' },
    ];

    return (
        <Box className="analytics-graph-container">
            <Typography variant="h6" gutterBottom>Team Indicators</Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
                Average indicators measured in meetings over time.
            </Typography>
            <Grid container spacing={2}>
                {indicators.map((indicator, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                        <IndicatorBar
                            name={indicator.name}
                            score={data[indicator.scoreKey]}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default AnalyticsIndicators;