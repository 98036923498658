import React, {useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import {
    Box,
    Typography,
    TextField,
    Chip,
    Avatar,
    Button,
    Alert, Tooltip,
} from '@mui/material';
import ModalOverlay from '../../components/modal/ModalOverlay';
import './css/CreateTeamPanel.css';
import UserSearchInput from "../../components/usersearchinput/UserSearchInput";
import config from '../../config';

const CreateTeamPanel = ({isOpen, onClose, teamId, onSave}) => {
    const [teamName, setTeamName] = useState('');
    const [description, setDescription] = useState('');
    const [creatorUser, setCreatorUser] = useState(null);
    const [manager, setManager] = useState(null);
    const [members, setMembers] = useState([]);
    const [availableUsers, setAvailableUsers] = useState([]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const fetchSharedMeetingUsers = useCallback(async () => {
        if (availableUsers.length === 0) {
            try {
                setLoading(true);
                const response = await axios.get(`${config.backendUrlApiV1}/shared-meeting-users/`);
                setAvailableUsers(response.data);
            } catch (error) {
                console.error('Error fetching shared meeting users:', error);
                setError('Failed to fetch users. Please try again.');
            } finally {
                setLoading(false);
            }
        }
    }, [availableUsers.length]);

    const fetchTeamData = useCallback(async () => {
        if (teamId) {
            try {
                setLoading(true);
                const response = await axios.get(`${config.backendUrlApiV1}/teams/${teamId}/`);
                const teamData = response.data;
                setTeamName(teamData.name || '');
                setDescription(teamData.description || '');
                setManager(teamData.manager || null);
                setMembers(teamData.members || []);
                setCreatorUser(teamData.creatorUser || null);
            } catch (error) {
                console.error('Error fetching team data:', error);
                setError('Failed to fetch team data. Please try again.');
            } finally {
                setLoading(false);
            }
        }
    }, [teamId]);

    useEffect(() => {
        fetchSharedMeetingUsers();
    }, [fetchSharedMeetingUsers]);

    useEffect(() => {
        if (!isOpen) {
            resetForm()
        } else if (teamId) {
            fetchTeamData();
        }
    }, [isOpen, teamId, fetchTeamData]);

    const resetForm = () => {
        setTeamName('');
        setDescription('');
        setManager(null);
        setMembers([]);
        setError('');
    };

    const handleSave = async () => {
        const teamData = {
            name: teamName,
            description,
            manager: manager?.id,
            members: members.map(m => m.id)
        };

        try {
            setLoading(true);
            setError('');
            if (teamId) {
                await axios.put(`${config.backendUrlApiV1}/teams/${teamId}/`, teamData);
            } else {
                await axios.post(`${config.backendUrlApiV1}/teams/`, teamData);
            }
            onSave();
            onClose();
        } catch (error) {
            console.error('Error saving team:', error);
            if (error.response && error.response.data && error.response.data.errors) {
                const errorMessages = Object.entries(error.response.data.errors)
                    .map(([key, messages]) => `${key}: ${messages.join(', ')}`)
                    .join('\n');
                setError(errorMessages);
            } else {
                setError('An error occurred while saving the team. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleManagerSelect = (selectedUser) => {
        setManager(selectedUser);
    };

    const handleMemberSelect = (selectedUser) => {
        setMembers(prev => [...prev, selectedUser]);
    };

    const handleRemoveMember = (memberToRemove) => {
        setMembers(prev => prev.filter((member) => member.id !== memberToRemove.id));
    };


    const UserChip = ({user, onDelete}) => (
        <Tooltip title={user.email} arrow>
            <Chip
                avatar={<Avatar>{(user.name || user.email).charAt(0).toUpperCase()}</Avatar>}
                label={user.name || user.email}
                onDelete={onDelete}
                className="member-chip"
            />
        </Tooltip>
    );

    return (
        <ModalOverlay title={teamId ? "Edit Team" : "Create Team"} isOpen={isOpen} onClose={onClose}>
            {error && (
                <Alert severity="error" style={{marginBottom: '1rem', whiteSpace: 'pre-line'}}>
                    {error}
                </Alert>
            )}
            <TextField
                label="Name"
                variant="outlined"
                required
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
                fullWidth
                margin="normal"
            />
            <Typography variant="subtitle1" className="input-label">
                Manager
            </Typography>
            {manager ? (
                <UserChip
                    user={manager}
                    onDelete={() => setManager(null)}
                />
            ) : (
                <UserSearchInput
                    availableUsers={availableUsers.filter(u => !members.some(m => m.id === u.id))}
                    onUserSelect={handleManagerSelect}
                />
            )}
            <Typography variant="subtitle1" className="input-label">
                Members* ({members.length})
            </Typography>
            <Box className="chip-container">
                {members.map((member) => (
                    <UserChip
                        key={member.id}
                        user={member}
                        onDelete={() => handleRemoveMember(member)}
                    />
                ))}
            </Box>
            <UserSearchInput
                availableUsers={availableUsers.filter(u => !members.some(m => m.id === u.id) && u.id !== manager?.id)}
                onUserSelect={handleMemberSelect}
            />
            <TextField
                label="A brief description about the team"
                variant="outlined"
                multiline
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                margin="normal"
            />
            {creatorUser && (
                <>
                    <Typography variant="subtitle1" className="input-label">
                        Created by
                    </Typography>
                    <UserChip user={creatorUser}/>
                </>
            )}

            <Button
                variant="contained"
                color="primary"
                className="modal-save-button"
                onClick={handleSave}
                disabled={loading}
            >
                {loading ? 'Saving...' : 'Save changes'}
            </Button>
        </ModalOverlay>
    );
};

export default CreateTeamPanel;