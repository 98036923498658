import React, {createContext, useState, useContext, useCallback} from 'react';

const ModalOverlayContext = createContext();

export const ModalOverlayProvider = ({children}) => {
    const [isOpen, setIsOpen] = useState(false);

    const openModal = useCallback(() => setIsOpen(true), []);
    const closeModal = useCallback(() => setIsOpen(false), []);

    return (
        <ModalOverlayContext.Provider value={{isOpen, openModal, closeModal}}>
            {children}
        </ModalOverlayContext.Provider>
    );
};

export const useModalOverlay = () => {
    const context = useContext(ModalOverlayContext);
    if (context === undefined) {
        throw new Error('useModelOverlay must be used within a ModalOverlayProvider');
    }
    return context;
};