import React, {useState, useEffect, useCallback, useRef} from 'react';
import axios from 'axios';
import {
    Box,
    CircularProgress,
    IconButton,
    Typography,
    Alert,
    Button, MenuItem, FormControl, Select
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import './TranscriptEditor.css';
import ModalOverlay from "../../../components/modal/ModalOverlay";
import config from "../../../config";
import {useUserSelection} from "../../../components/usersearchinput/UseUserSelection";
import {UserSelectionInput} from "../../../components/usersearchinput/UserSelection";
import {useRefresh} from "../../../auth/RefreshProvider";

const TranscriptEditor = ({isOpen, onClose, transcript, onSave}) => {
    const [selectedTeamId, setSelectedTeamId] = useState('');
    const [teams, setTeams] = useState({
        selected: null,
        meetings: [] // Initialize with empty array instead of undefined
    });

    const [audioSnippets, setAudioSnippets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [activeSource, setActiveSource] = useState(null);
    const audioContextRef = useRef(null);
    const {triggerRefresh} = useRefresh();

    const {
        selections,
        handleSelect,
        handleRemove,
        getFilteredUsers,
        setSelections
    } = useUserSelection({
        fetchUrl: `${config.backendUrlApiV1}/shared-meeting-users/`,
        maxSelections: {},
        filterRules: {
            default: (user, selections) => {
                return !Object.values(selections).some(selectedUser =>
                    selectedUser && selectedUser.id === user.id
                );
            }
        }
    });

    const fetchAudioSnippet = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(
                `${config.backendUrlApiV1}/transcript/${transcript.metaData.transcriptId}/get_speakers_details/`,
                {
                    params: {
                        meetingId: transcript.metaData.meetingId // Add meeting ID to params
                    }
                }
            );

            setTeams(response.data.teams);
            setSelectedTeamId(response.data.teams.selected?.id || '');
            console.log(response.data.teams)
            setAudioSnippets(response.data.snippets);

            const initialSelections = {};
            response.data.snippets.forEach(snippet => {
                if (snippet.speakerName) {
                    initialSelections[snippet.speakerIdOriginal] = {
                        id: snippet.userId,
                        name: snippet.speakerName,
                        email: snippet.email
                    };
                }
            });
            setSelections(initialSelections);

        } catch (error) {
            console.error('Error fetching audio snippets:', error);
            setError('Failed to fetch audio snippets');
        } finally {
            setLoading(false);
        }
    }, [transcript, setSelections]);


    useEffect(() => {
        if (isOpen) {
            fetchAudioSnippet();
        }
    }, [isOpen, fetchAudioSnippet]);

    const playBase64Audio = async (index, base64Data) => {
        try {
            // Stop current playback if any
            if (activeSource) {
                activeSource.stop();
                setActiveSource(null);
            }

            // Create audio from base64
            const audioData = atob(base64Data);
            const arrayBuffer = new ArrayBuffer(audioData.length);
            const view = new Uint8Array(arrayBuffer);
            for (let i = 0; i < audioData.length; i++) {
                view[i] = audioData.charCodeAt(i);
            }

            // Create new audio context if needed
            if (!audioContextRef.current) {
                audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
            }

            const audioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);
            const source = audioContextRef.current.createBufferSource();
            source.buffer = audioBuffer;
            source.connect(audioContextRef.current.destination);

            // Store reference
            setActiveSource(source);

            // Handle playback end
            source.onended = () => {
                setIsPlaying(false);
                setCurrentPlayingIndex(null);
                setActiveSource(null);
            };

            // Start playing
            source.start(0);
            setIsPlaying(true);
            setCurrentPlayingIndex(index);

        } catch (error) {
            console.error('Error playing audio:', error);
            setError('Failed to play audio snippet');
            // Reset states on error
            setIsPlaying(false);
            setCurrentPlayingIndex(null);
            setActiveSource(null);
        }
    };

    const togglePlayPause = (index, audioData) => {
        if (currentPlayingIndex === index && isPlaying) {
            if (activeSource) {
                activeSource.stop();
                setActiveSource(null);
            }
            setIsPlaying(false);
            setCurrentPlayingIndex(null);
        } else {
            playBase64Audio(index, audioData);
        }
    };

    useEffect(() => {
        return () => {
            if (activeSource) {
                activeSource.stop();
            }
            if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
                audioContextRef.current.close();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isOpen) {
            if (activeSource) {
                activeSource.stop();
                setActiveSource(null);
            }
            setIsPlaying(false);
            setCurrentPlayingIndex(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const formatTime = (time) => {
        const seconds = Math.floor(time / 1000);
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    const handleSave = async () => {
        try {
            const updates = Object.entries(selections).map(([speakerIdOriginal, user]) => ({
                speaker_id_original: speakerIdOriginal,
                name: user ? user.name : '',
                user_id: user.id
            }));
            setLoading(true);
            const response = await axios.post(
                `${config.backendUrlApiV1}/transcript/${transcript.metaData.transcriptId}/edit_speaker_bulk/`,
                {
                    updates,
                    meetingId: transcript.metaData.meetingId,
                    teamId: selectedTeamId || null
                }
            );

            if (response.status === 200) {
                onSave();
                onClose();
                triggerRefresh();
                setLoading(false);
            }
        } catch (error) {
            console.error('Error saving speaker assignments:', error);
            setError('Failed to save speaker assignments');
        }
    };

    return (
        <ModalOverlay title="Edit Speaker Names" isOpen={isOpen} onClose={onClose}>
            {loading ? (
                <Box display="flex" justifyContent="center" p={4}>
                    <CircularProgress/>
                </Box>
            ) : error ? (
                <Alert severity="error" sx={{mb: 2}}>{error}</Alert>
            ) : (
                <Box className="modal-body transcript-editor">
                    <Box>
                        <Typography variant="subtitle1" className="section-title">
                            Team
                        </Typography>
                        <FormControl fullWidth>
                            <Select
                                value={selectedTeamId}
                                onChange={(e) => setSelectedTeamId(e.target.value)}
                                displayEmpty
                            >
                                <MenuItem value="">
                                    <em>Auto Select a team, if there's a match</em>
                                </MenuItem>
                                {teams.meetings.map((team) => (
                                    <MenuItem key={team.id} value={team.id}>
                                        {team.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box>
                        <Typography variant="subtitle1" className="section-title">
                            Team Members
                        </Typography>
                        {audioSnippets.map((snippet, index) => (
                            <Box key={index} className="transcript-line">
                                <Box className="speaker-section">
                                    <UserSelectionInput
                                        role={`${snippet.speakerIdOriginal}`}
                                        availableUsers={getFilteredUsers('default')}
                                        selectedUser={selections[`${snippet.speakerIdOriginal}`]}
                                        onSelect={handleSelect}
                                        onRemove={handleRemove}
                                        placeholder={`Assign ${snippet.speakerName}...`}
                                    />
                                </Box>
                                <Box className="audio-row">
                                    <Box className="audio-section">
                                        <IconButton
                                            onClick={() => togglePlayPause(index, snippet.audioData)}
                                            className="play-button"
                                            size="small"
                                        >
                                            {currentPlayingIndex === index && isPlaying ? (
                                                <PauseIcon fontSize="small"/>
                                            ) : (
                                                <PlayArrowIcon fontSize="small"/>
                                            )}
                                        </IconButton>
                                        <Box className="slider-container">
                                            <Typography variant="caption" className="time-info">
                                                Original
                                                timing: {formatTime(snippet.startTime)} - {formatTime(snippet.endTime)}
                                            </Typography>
                                            <Typography variant="caption" className="duration-info">
                                                Duration: {formatTime(snippet.duration)}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>

                    <Button
                        variant="contained"
                        color="primary"
                        className="modal-save-button"
                        onClick={handleSave}
                        disabled={loading}
                    >
                        {loading ? 'Saving...' : 'Save changes'}
                    </Button>
                </Box>
            )}
        </ModalOverlay>
    );
};

export default TranscriptEditor;