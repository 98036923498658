import React from 'react';
import {Typography, Button, Box} from '@mui/material';
import './DeleteModal.css';
import ModalOverlay from "../modal/ModalOverlay";

const DeleteModal = ({isOpen, onClose, onDelete, title, message}) => {
    return (
        <ModalOverlay title={title} isOpen={isOpen} onClose={onClose}>
            <Typography variant="body1" className="delete-confirmation-message">
                {message}
            </Typography>
            <Box className="delete-confirmation-actions">
                <Button
                    variant="outlined"
                    onClick={onClose}
                    className="cancel-button"
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    onClick={onDelete}
                    className="delete-button"
                >
                    Delete
                </Button>
            </Box>
        </ModalOverlay>
    );
};

export default DeleteModal;