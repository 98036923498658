import React, {useState, useMemo, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import {Box, Button, Checkbox, FormControlLabel, Typography} from '@mui/material';
import './css/AnalyticsIndicatorsChart.css';
import CustomTooltip from "../../components/customtooltip/CustomTooltip";

const indicators = [
    {key: 'openness', color: '#8884d8'},
    {key: 'supportiveness', color: '#82ca9d'},
    {key: 'leadership', color: '#ffc658'},
    {key: 'trust', color: '#ff7300'},
    {key: 'reflection', color: '#00C49F'},
    {key: 'blame', color: '#FFBB28'},
    {key: 'meetingType', color: '#FF8042'},
];

const AnalyticsIndicatorsChart = ({historicalData}) => {
    const [selectedIndicators, setSelectedIndicators] = useState(indicators.map(i => i.key));

    const toggleIndicator = (indicator) => {
        setSelectedIndicators(prev =>
            prev.includes(indicator)
                ? prev.filter(i => i !== indicator)
                : [...prev, indicator]
        );
    };

    const selectAll = () => setSelectedIndicators(indicators.map(i => i.key));
    const clearAll = () => setSelectedIndicators([]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}`;
    };

    const chartData = useMemo(() => {
        const dataMap = new Map();
        historicalData.forEach(item => {
            const date = formatDate(item['meta.meetingDate']);
            if (!dataMap.has(date)) {
                dataMap.set(date, []);
            }
            dataMap.get(date).push(item);
        });

        // Sort dates in chronological order (oldest to newest)
        const sortedDates = Array.from(dataMap.keys()).sort((a, b) => {
            const dateA = new Date(a.split('/').reverse().join('-'));
            const dateB = new Date(b.split('/').reverse().join('-'));
            return dateA - dateB;  // Changed from dateB - dateA
        });

        return sortedDates.flatMap(date => {
            const meetings = dataMap.get(date);
            return meetings.map((meeting, index) => ({
                date: meetings.length > 1 ? `${date} (${index + 1})` : date,
                ...indicators.reduce((acc, {key}) => {
                    acc[key] = meeting[`${key}.score`];
                    return acc;
                }, {}),
                meta: {
                    meetingId: meeting['meta.meetingId'],
                    meetingName: meeting['meta.meetingName']
                }
            }));
        });
    }, [historicalData]);

    const handleTooltipClick = useCallback((meetingData) => {
        window.open(`/product/meeting/${meetingData.meta.meetingId}`, '_blank');
    }, []);

    return (
        <Box className="analytics-indicators-chart">
            <Typography variant="h6">Culture Index</Typography>
            <Box className="chart-controls">
                <Button onClick={selectAll}>Select All</Button>
                <Button onClick={clearAll}>Clear All</Button>
                {indicators.map(({key, color}) => (
                    <FormControlLabel
                        key={key}
                        control={
                            <Checkbox
                                checked={selectedIndicators.includes(key)}
                                onChange={() => toggleIndicator(key)}
                                style={{color}}
                            />
                        }
                        label={key}
                    />
                ))}
            </Box>
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    data={chartData}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis
                        dataKey="date"
                        tick={{fontSize: 12}}
                        tickFormatter={(value) => value}
                    />
                    <YAxis
                        domain={[0, 100]}
                        ticks={[0, 25, 50, 75, 100]}
                        tick={{fontSize: 12}}
                    />
                    <Tooltip
                        content={({active, payload, label, coordinate}) => (
                            <CustomTooltip
                                active={active}
                                payload={payload}
                                label={label}
                                coordinate={coordinate}
                                onClick={handleTooltipClick}
                                valueFormatter={(value) => value}
                            />
                        )}
                    />
                    <Legend/>
                    {indicators.map(({key, color}) => (
                        selectedIndicators.includes(key) && (
                            <Line
                                key={key}
                                type="monotone"
                                dataKey={key}
                                stroke={color}
                                activeDot={{r: 8}}
                            />
                        )
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default AnalyticsIndicatorsChart;