import React from 'react';
import GoogleButton from 'react-google-button';
import config from '../config';

const GoogleAuth = () => {

    return (
        <div>
            <GoogleButton
                onClick={() => {
                    const GOOGLE_AUTH_URL = 'https://accounts.google.com/o/oauth2/v2/auth';

                    const scope = [
                        'https://www.googleapis.com/auth/userinfo.email',
                        'https://www.googleapis.com/auth/userinfo.profile',
                        // 'https://www.googleapis.com/auth/calendar.readonly',
                        'https://www.googleapis.com/auth/calendar.events.readonly'
                    ].join(' ');

                    const params = {
                        response_type: 'code',
                        client_id: config.googleClientId,
                        redirect_uri: config.googleRedirectUri,
                        prompt: 'consent',
                        access_type: 'offline',
                        scope: scope,
                    };

                    const urlParams = new URLSearchParams(params).toString();
                    window.location = `${GOOGLE_AUTH_URL}?${urlParams}`;
                }}
            />
        </div>
    );
};

export default GoogleAuth;
