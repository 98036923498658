import React, {useState} from 'react';
import {Typography, Box, Tabs, Tab} from '@mui/material';
import Teams from './Teams';
import './css/Company.css';
import {TooltipWrapper} from "../../components/TooltipWrapper";


const Company = () => {
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    return (
        <Box className="tab-curved-view">
            <Box className="tab-header">
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    aria-label="Company Management Tabs"
                >
                    <Tab label="Teams"/>
                    <TooltipWrapper title="Page under construction">
                        <Tab label="People" disabled/>
                    </TooltipWrapper>
                </Tabs>
            </Box>
            <Box className="tab-panel" hidden={tabIndex !== 0}>
                <Teams/>
            </Box>
            <Box className="tab-panel" hidden={tabIndex !== 1}>
                <Typography>People tab content will go here</Typography>
            </Box>
        </Box>
    );
};

export default Company;