import React, {useEffect, useState, useCallback, useRef} from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    IconButton,
    useMediaQuery,
    useTheme,
    Tabs,
    Tab
} from '@mui/material';
import {ChevronLeft, ChevronRight} from '@mui/icons-material';
import './Meetings.css';
import axios from "axios";
import config from "../config";

const numberOfRecordsPerPage = 25;

const Meetings = () => {
    const [meetingSets, setMeetingSets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [paginationStates, setPaginationStates] = useState({});
    const pollingInterval = useRef(null);

    const POLLING_INTERVAL = 20000; // 20 seconds
    const MAX_POLLING_TIME = 600000; // 10 minutes
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const fetchMeetingsForActiveTab = useCallback(async (page) => {
        const currentSet = meetingSets[activeTab];
        if (!currentSet) return;

        setLoading(true);
        try {
            const response = await axios.get(`${config.backendUrlApiV1}/meetings/weekly_view/`, {
                params: {
                    userId: currentSet.userId,
                    page: page
                }
            });

            const {data} = response.data;
            if (data?.length > 0) {
                // Update only the current tab's meetings data
                setMeetingSets(prev => prev.map((set, index) =>
                    index === activeTab ? {
                        ...set,
                        meetings: data[0].meetings,
                        totalPages: data[0].totalPages,
                        totalRecords: data[0].totalRecords
                    } : set
                ));
            }
        } catch (error) {
            console.error('Error fetching meetings:', error);
        } finally {
            setLoading(false);
        }
    }, [activeTab, meetingSets]);
    const checkMeetingStatus = useCallback(async () => {
        const currentSet = meetingSets[activeTab];
        if (!currentSet?.meetings) return;

        // Get unready meeting IDs from current set
        const unreadyMeetings = currentSet.meetings
            .flatMap(week => week.items)
            .filter(meeting => !meeting.ready)
            .map(meeting => meeting.id);

        if (unreadyMeetings.length === 0) {
            if (pollingInterval.current) {
                clearInterval(pollingInterval.current);
                pollingInterval.current = null;
            }
            return;
        }

        try {
            const response = await axios.get(`${config.backendUrlApiV1}/meetings/check_status/`, {
                params: {
                    meeting_ids: unreadyMeetings.join(',')
                }
            });

            const {completedMeetings} = response.data;
            if (completedMeetings?.length > 0) {
                // Update ready status for completed meetings
                setMeetingSets(prev => prev.map((set, index) =>
                    index === activeTab ? {
                        ...set,
                        meetings: set.meetings.map(week => ({
                            ...week,
                            items: week.items.map(meeting => ({
                                ...meeting,
                                ready: meeting.ready || completedMeetings.includes(meeting.id)
                            }))
                        }))
                    } : set
                ));
            }
        } catch (error) {
            console.error('Error checking meeting status:', error);
        }
    }, [meetingSets, activeTab]);
    const startPolling = useCallback(() => {
        if (pollingInterval.current) {
            clearInterval(pollingInterval.current);
        }

        const startTime = Date.now();
        checkMeetingStatus();

        pollingInterval.current = setInterval(() => {
            if (Date.now() - startTime > MAX_POLLING_TIME) {
                clearInterval(pollingInterval.current);
                pollingInterval.current = null;
                return;
            }
            checkMeetingStatus();
        }, POLLING_INTERVAL);
    }, [checkMeetingStatus]);

    useEffect(() => {
        // Initial fetch only
        const initializeMeetings = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${config.backendUrlApiV1}/meetings/weekly_view/`);
                const {data} = response.data;
                if (data?.length > 0) {
                    setMeetingSets(data);
                    // Initialize pagination states
                    const initialPaginationStates = {};
                    data.forEach(set => {
                        initialPaginationStates[set.userId] = {
                            currentPage: 1,
                            totalPages: set.totalPages,
                            totalRecords: set.totalRecords
                        };
                    });
                    setPaginationStates(initialPaginationStates);
                }
            } catch (error) {
                console.error('Error initializing meetings:', error);
            } finally {
                setLoading(false);
            }
        };

        initializeMeetings();
    }, []);
    // Start polling when meetings change or tab changes
    useEffect(() => {
        startPolling();
        return () => {
            if (pollingInterval.current) {
                clearInterval(pollingInterval.current);
            }
        };
    }, [meetingSets, activeTab, startPolling]);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleMeetingClick = (meetingId) => {
        // navigate(`/product/meeting/${meetingId}`);
        window.open(`/product/meeting/${meetingId}`, '_blank');
    };

    const handlePageChange = (newPage) => {
        const currentSet = meetingSets[activeTab];
        if (!currentSet) return;

        const currentPagination = paginationStates[currentSet.userId];
        if (newPage >= 1 && newPage <= currentPagination.totalPages) {
            // Update pagination state
            setPaginationStates(prev => ({
                ...prev,
                [currentSet.userId]: {
                    ...prev[currentSet.userId],
                    currentPage: newPage
                }
            }));
            fetchMeetingsForActiveTab(newPage);
        }
    };

    // Get current pagination state for active tab
    const getCurrentPagination = useCallback(() => {
        const currentSet = meetingSets[activeTab];
        if (!currentSet) return {currentPage: 1, totalPages: 1, totalRecords: 0};

        return paginationStates[currentSet.userId] || {
            currentPage: 1,
            totalPages: 1,
            totalRecords: 0
        };
    }, [activeTab, meetingSets, paginationStates]);

    const renderPaginationControls = () => {
        const {currentPage, totalPages, totalRecords} = getCurrentPagination();
        return (
            <Box className="pagination-controls">
                <Typography className="pagination-text">
                    {`${(currentPage - 1) * numberOfRecordsPerPage + 1}-${Math.min(currentPage * numberOfRecordsPerPage, totalRecords)} of ${totalRecords}`}
                </Typography>
                <IconButton
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="pagination-button"
                >
                    <ChevronLeft/>
                </IconButton>
                <IconButton
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="pagination-button"
                >
                    <ChevronRight/>
                </IconButton>
            </Box>
        );
    };

    const renderTableHeader = () => (
        <Box className="table-header">
            <Box className="header-content">
                <Typography className="header-text meeting">Meeting</Typography>
                <Typography className="header-text date">Date</Typography>
                <Typography className="header-text meeting-time">Time</Typography>
                <Typography className="header-text duration">Duration</Typography>
            </Box>
            {!isMobile && renderPaginationControls()}
        </Box>
    );

    const renderMeetingRow = (meeting, index) => {
        const isDisabled = !meeting.ready;

        return (
            <Box
                key={index}
                className={`table-row ${isDisabled ? 'disabled' : ''}`}
                onClick={() => !isDisabled && handleMeetingClick(meeting.id)}
            >
                {isMobile ? (
                    <Box className="mobile-row-content">
                        <Typography className="row-text name">{meeting.name}</Typography>
                        <Box className="mobile-row-details">
                            <Typography className="row-text date">{meeting.date}</Typography>
                            <Typography className="row-text row-time">{meeting.time}</Typography>
                            <Typography className="row-text duration">{meeting.duration}</Typography>
                        </Box>
                        {isDisabled && (
                            <Box className="processing-indicator">
                                <CircularProgress size={16}/>
                                <Typography variant="caption">Processing...</Typography>
                            </Box>
                        )}
                    </Box>
                ) : (
                    <>
                        <Typography className="row-text name">{meeting.name}</Typography>
                        <Typography className="row-text date">{meeting.date}</Typography>
                        <Typography className="row-text row-time">{meeting.time}</Typography>
                        <Typography className="row-text duration">{meeting.duration}</Typography>
                        {isDisabled && (
                            <Box className="processing-indicator">
                                <CircularProgress size={16}/>
                                <Typography variant="caption">Processing...</Typography>
                            </Box>
                        )}
                    </>
                )}
            </Box>
        );
    };

    const renderTableContent = () => {
        if (loading) {
            return (
                <Box className="loading-container">
                    <CircularProgress/>
                </Box>
            );
        }

        const currentMeetingSet = meetingSets[activeTab];
        if (!currentMeetingSet?.meetings?.length) {
            return (
                <Box className="table-header center-align">
                    <Typography className="no-meetings-text">
                        No meetings to display
                    </Typography>
                </Box>
            );
        }

        return (
            <>
                {currentMeetingSet.meetings.map((week) => (
                    <React.Fragment key={week.week}>
                        <Box className="table-header center-align">
                            <Typography className="week-text">{week.week}</Typography>
                        </Box>
                        {week.items.map(renderMeetingRow)}
                    </React.Fragment>
                ))}
                {isMobile && (
                    <Box className="mobile-pagination">
                        {renderPaginationControls()}
                    </Box>
                )}
            </>
        );
    };

    const mainContent = (
        <Box className="table-container">
            {renderTableHeader()}
            {renderTableContent()}
        </Box>
    );

    // Render without tabs if only one meeting set
    if (!meetingSets.length || meetingSets.length <= 1) {
        return mainContent;
    }

    // Render with tabs for multiple meeting sets
    return (
        <Box className="tab-curved-view">
            <Box className="tab-header">
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    aria-label="meeting tabs"
                    className="meetings-tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {meetingSets.map((set) => (
                        <Tab
                            key={set.userId}
                            label={set.tabName}
                            className="meeting-tab"
                        />
                    ))}
                </Tabs>
            </Box>
            <Box className="tab-panel">
                {mainContent}
            </Box>
        </Box>
    );
};

export default Meetings;