import React, { useEffect, useState, useMemo } from 'react';
import { Box, Typography, Chip, Popover, Tooltip, useTheme } from '@mui/material';
import './HeartbeatTimeline.css';
import config from "../../../config";
import TopicInfoCard from './TopicInfoCard';
import SignificantEventInfoCard from './SignificantEventInfoCard';
import { alpha } from '@mui/material/styles';
import {trackInteraction} from "../../../common/googleanalytics";

const HeartbeatTimeline = ({ transcriptData, meetingIntelligence, duration, currentTime, onTimeChange }) => {
    const theme = useTheme();
    const [speakerColors, setSpeakerColors] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverContent, setPopoverContent] = useState(null);

    const open = Boolean(anchorEl);
    const { transcript } = transcriptData || {};
    const { utterances = [], speakerNames = [] } = transcript || {};

    const sortedSpeakerNames = useMemo(() =>
        [...speakerNames].sort((a, b) => a.localeCompare(b)),
    [speakerNames]);

    const colors = config.colorsBright;

    useEffect(() => {
        if (sortedSpeakerNames.length > 0) {
            const newSpeakerColors = {};
            sortedSpeakerNames.forEach((speaker, index) => {
                newSpeakerColors[speaker] = colors[index % colors.length];
            });
            setSpeakerColors(newSpeakerColors);
        }
    }, [sortedSpeakerNames, colors]);

    const handlePopoverOpen = (event, content) => {
        setAnchorEl(event.currentTarget);
        setPopoverContent(content);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setPopoverContent(null);
    };

    const handleJumpToTopic = (startTime) => {
        if (onTimeChange) {
            onTimeChange(startTime / 1000); // Convert milliseconds to seconds for external interface
        }
    };

    const calculateItemPosition = (startTime, endTime) => {
        const durationMs = duration * 1000; // Ensure duration is in milliseconds
        const start = (startTime / durationMs) * 100;
        const width = Math.max(((endTime - startTime) / durationMs) * 100, 2); // Minimum width of 2%
        return { left: `${start}%`, width: `${width}%` };
    };



    const handleSignificantEventClick = (event, e) => {
        trackInteraction.buttonClick('timeline_significant_event');
        handlePopoverOpen(e,
            <SignificantEventInfoCard
                event={event}
                open={true}
                onClose={handlePopoverClose}
                onJumpToEvent={() => handleJumpToTopic(event.startTime)}
            />
        );

    };

    const handleTopicClick = (topic, e) => {
        trackInteraction.buttonClick('timeline_topic_card');
        handlePopoverOpen(e,
            <TopicInfoCard
                topic={topic}
                open={true}
                onClose={handlePopoverClose}
                onJumpToTopic={handleJumpToTopic}
            />
        );
    };

    if (!transcriptData || !transcript || utterances.length === 0 || duration === 0) {
        return <Typography>Loading transcript data...</Typography>;
    }

    return (
        <Box className="heartbeat-timeline">
            <Box className="speaker-container">
                <Typography variant="body2" className="speaker-name">Topics</Typography>
                <Box className="heartbeat-row">
                    <Box className="timeline" style={{ position: 'relative', height: '30px', width: '100%' }}>
                        {meetingIntelligence && meetingIntelligence.topics && meetingIntelligence.topics.map((topic, index) => {
                            const position = calculateItemPosition(topic.startTime, topic.endTime);
                            const topicColor = theme.palette.info.main;
                            return (
                                <Tooltip title={topic.name} key={index}>
                                    <Chip
                                        label={topic.name}
                                        size="small"
                                        className="timeline-chip topic"
                                        style={{
                                            position: 'absolute',
                                            left: position.left,
                                            width: position.width,
                                            backgroundColor: alpha(topicColor, 0.8),
                                            color: theme.palette.getContrastText(topicColor),
                                            border: `1px solid ${topicColor}`,
                                            zIndex: 10,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                        onClick={(e) => handleTopicClick(topic, e)}
                                    />
                                </Tooltip>
                            );
                        })}
                    </Box>
                </Box>
            </Box>
            <Box className="speaker-container">
                <Typography variant="body2" className="speaker-name">Significant Events</Typography>
                <Box className="heartbeat-row">
                    <Box className="timeline" style={{ position: 'relative', height: '30px', width: '100%' }}>
                        {meetingIntelligence && meetingIntelligence.significantEvents && meetingIntelligence.significantEvents.map((event, index) => {
                            const position = calculateItemPosition(event.startTime, event.endTime);
                            const eventColor = theme.palette.info.main;
                            return (
                                <Tooltip title={event.eventName} key={index}>
                                    <Chip
                                        label={event.eventName}
                                        size="small"
                                        className="timeline-chip event"
                                        style={{
                                            position: 'absolute',
                                            left: position.left,
                                            width: position.width,
                                            minWidth: '75px', // Minimum width for better touch targets
                                            backgroundColor: alpha(eventColor, 0.8),
                                            color: theme.palette.getContrastText(eventColor),
                                            border: `1px solid ${eventColor}`,
                                            zIndex: 10,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                        onClick={(e) => handleSignificantEventClick(event, e)}
                                    />
                                </Tooltip>
                            );
                        })}
                    </Box>
                </Box>
            </Box>
            {sortedSpeakerNames.map((speaker) => (
                <Box key={speaker} className="speaker-container">
                    <Typography variant="body2" className="speaker-name">{speaker}</Typography>
                    <Box className="heartbeat-row">
                        <Box className="timeline" style={{ position: 'relative', height: '30px', width: '100%' }}>
                            {utterances
                                .filter(entry => entry.speakerName === speaker)
                                .map((entry, index) => (
                                    <Box
                                        key={index}
                                        className="utterance"
                                        style={{
                                            position: 'absolute',
                                            left: `${(entry.start / (duration * 1000)) * 100}%`,
                                            width: `${((entry.end - entry.start) / (duration * 1000)) * 100}%`,
                                            backgroundColor: speakerColors[speaker],
                                            height: '100%',
                                        }}
                                    />
                                ))}
                        </Box>
                    </Box>
                </Box>
            ))}
            <Box
                className="playhead"
                style={{
                    position: 'absolute',
                    left: `${(currentTime / duration) * 100}%`,
                    height: `${(sortedSpeakerNames.length + 2) * 60}px`,
                    top: 0,
                    width: '2px',
                    backgroundColor: 'red',
                    zIndex: 20,
                }}
            />
            <Popover
                id="mouse-over-popover"
                className="styled-popover"
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                {popoverContent}
            </Popover>
        </Box>
    );
};

export default HeartbeatTimeline;
