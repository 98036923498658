import React, {useState} from 'react';
import {Typography, Grid, Chip, Box, Select, MenuItem, FormControl, InputLabel} from '@mui/material';
import InteractionMap from './InteractionMap';
import {PieChart, Pie, Cell, Tooltip as RechartsTooltip, ResponsiveContainer} from 'recharts';
import Transcription from "../transcript/Transcription";
import './MeetingAnalysis.css';
import IndicatorsRadarChart from './IndicatorsRadarChart';
import config from "../../../config";
import TranscriptEditor from "../transcript/TranscriptEditor";
import Button from "@mui/material/Button";
import UserChip from "../../../components/usersearchinput/UserChip";

const colors = config.colorsBright

const MeetingAnalysis = ({data, transcript}) => {
    const [view, setView] = useState('all');
    const [transcriptData, setTranscriptData] = useState(transcript);
    const [showEditor, setShowEditor] = useState(false);

    if (!data || Object.keys(data).length === 0) {
        return null;
    }

    const meetingTitle = transcript?.metaData?.meetingName || 'Unknown Meeting Name';
    const metaData = transcript.metaData;

    const viewOptions = [
        'all',
        ...Object.keys(data)
            .filter(key => key !== 'all')
            .sort((a, b) => a.localeCompare(b))
    ];

    const allData = data.all || {};
    const filteredData = view === 'all' ? allData : data[view] || {};

    const pieData = (allData.ratio || []).map(speaker => ({
        name: speaker.speakerName,
        value: speaker.speakerRatio,
    }));

    const radarData = (filteredData.llmIndicators || []).map(indicator => {
        const score = filteredData[indicator]?.score;
        return {
            indicator: indicator,
            score: typeof score === 'number' && !isNaN(score) ? score : 0
        };
    }).filter(item => item.score > 0);

    const analysisComponents = [
        {
            title: "Meeting Meta data",
            content: (
                <div className="metadata-container">
                    <div className="metadata-row">
                        <Typography variant="subtitle1" className="metadata-label">Date:</Typography>
                        <Typography>{metaData.meetingDate}</Typography>
                    </div>
                    <div className="metadata-row">
                        <Typography variant="subtitle1" className="metadata-label">Duration:</Typography>
                        <Typography>{metaData.meetingDuration}</Typography>
                    </div>
                    <div className="metadata-row">
                        <Typography variant="subtitle1" className="metadata-label">Platform:</Typography>
                        <Typography>{metaData.meetingPlatform}</Typography>
                    </div>
                    <div className="metadata-row participants">
                        <Typography variant="subtitle1" className="metadata-label">Participants:</Typography>
                        <div className="metadata-participants-grid">
                            {metaData.meetingParticipants.map(participant => (
                                <UserChip key={participant.id} user={participant}/>
                            ))}
                        </div>
                    </div>
                </div>
            ),
            gridProps: {xs: 12, sm: 6},
        },
        {
            title: "Indicators",
            content: <IndicatorsRadarChart data={radarData}/>,
            gridProps: {xs: 12, sm: 6},
        },
        {
            title: "Interaction Map",
            content: <InteractionMap data={allData.interactionMap}/>,
            gridProps: {xs: 12, sm: 6},
        },
        {
            title: "Speaking Time",
            content: (
                <div className="chart-container">
                    <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                            <Pie
                                dataKey="value"
                                isAnimationActive={false}
                                data={pieData}
                                cx="50%"
                                cy="50%"
                                outerRadius="80%"
                                fill="#8884d8"
                                labelLine={true}
                                label={({name, percent}) => `${name} ${(percent * 100).toFixed(0)}%`}
                            >
                                {pieData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} id={index} fill={colors[index % colors.length]}/>
                                ))}
                            </Pie>
                            <RechartsTooltip/>
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            ),
            gridProps: {xs: 12, sm: 6},
        },
    ];

    const handleTranscriptUpdate = async () => {
    };

    return (
        <Grid container spacing={1} className="analysis-container">
            <Grid item xs={12}>
                <Box className="transcript-header">
                    <div className="title-container">
                        <Typography variant="h3" className="transcript-title">
                            {meetingTitle}
                        </Typography>
                    </div>
                    <div className="transcript-controls">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setShowEditor(true)}
                        >
                            Edit team & speakers
                        </Button>
                        <FormControl className="view-select">
                            <InputLabel id="view-select-label">View</InputLabel>
                            <Select
                                labelId="view-select-label"
                                id="view-select"
                                value={view}
                                label="View"
                                onChange={(e) => setView(e.target.value)}
                            >
                                {viewOptions.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option === 'all' ? 'All' : option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </Box>
                <TranscriptEditor
                    isOpen={showEditor}
                    onClose={() => setShowEditor(false)}
                    transcript={transcript}
                    onSave={handleTranscriptUpdate}
                />
            </Grid>
            <Grid item xs={12} md={8} className="analysis-content">
                <Grid container spacing={1}>
                    {analysisComponents.map((component, index) => (
                        <Grid item {...component.gridProps} key={index} className="analysis-component">
                            <Box className="outlined-box">
                                <Typography variant="h6" gutterBottom>{component.title}</Typography>
                                <Box className="content-box">
                                    {component.content}
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
                <Box className="grid-item grid-item-transcription">
                    <Transcription data={transcriptData} setData={setTranscriptData}/>
                </Box>
            </Grid>
        </Grid>
    );
};

export default MeetingAnalysis;