import React, {useEffect, useRef} from 'react';
import {DataSet, Network} from 'vis-network/standalone/esm/vis-network';
import './InteractionMap.css';

const InteractionMap = ({data}) => {
    const networkRef = useRef(null);

    useEffect(() => {
        if (networkRef.current && data && data.participants && data.interactions) {
            const {participants, interactions} = data;

            if (participants.length === 0 || interactions.length === 0) {
                return;
            }

            const angleStep = (2 * Math.PI) / participants.length;
            const nodes = new DataSet(
                participants.map((participant, index) => {
                    const x = 100 * Math.cos(index * angleStep);
                    const y = 100 * Math.sin(index * angleStep);
                    return {
                        id: participant.id,
                        label: String(participant.name),
                        shape: 'dot',
                        x: x,
                        y: y,
                        size: 15
                    };
                })
            );

            const totalCount = interactions.reduce((total, interaction) => total + interaction.count, 0);
            const edges = new DataSet(
                interactions.map((interaction) => {
                    const ratio = interaction.count / totalCount * 5;
                    return {
                        from: interaction.from,
                        to: interaction.to,
                        value: ratio,
                        title: `Count: ${String(interaction.count)}`
                    };
                })
            );

            const networkData = {nodes: nodes, edges: edges};

            const options = {
                nodes: {
                    color: {background: '#FFFF00', border: '#2B7CE9'},
                    font: {color: '#000000'}
                },
                edges: {
                    color: '#4CAF50',
                    scaling: {
                        min: 0,
                        max: 7,
                        customScalingFunction: (min, max, total, value) => Number(value)
                    },
                    smooth: {type: 'continuous'}
                },
                physics: {
                    enabled: false,
                    barnesHut: {
                        gravitationalConstant: -30000,
                        springConstant: 0.04,
                        springLength: 30
                    }
                },
                interaction: {zoomView: false}
            };

            const network = new Network(networkRef.current, networkData, options);

            const resizeNetwork = () => {
                network.fit({
                    animation: {
                        duration: 1000,
                        easingFunction: 'easeOutQuint'
                    }
                });
            };

            resizeNetwork();
            window.addEventListener('resize', resizeNetwork);

            return () => {
                window.removeEventListener('resize', resizeNetwork);
            };
        }
    }, [data]);

    if (!data || !data.participants || !data.interactions) {
        return <div className="interaction-map-container">No interaction data available</div>;
    }

    return (
        <div className="interaction-map-container">
            <div ref={networkRef} className="interaction-map"/>
        </div>
    );
};

export default InteractionMap;