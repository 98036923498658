import React, {useState} from 'react';
import {Box, TextField, Button} from '@mui/material';
import axios from "axios";
import config from "../../config";
import ModalOverlay from '../../components/modal/ModalOverlay';

const Feedback = ({open, onClose}) => {
    const [title, setTitle] = useState('');
    const [feedback, setFeedback] = useState('');

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleFeedbackChange = (event) => {
        setFeedback(event.target.value);
    };

    const handleSubmitFeedback = async () => {
        const postData = {
            title: title,
            feedback: feedback
        };
        try {
            await axios.post(`${config.backendUrlApiV1}/submit-feedback/`, postData);
            setTitle('')
            setFeedback('')
            onClose();
        } catch (error) {
            console.error('Error Sending Feedback:', error);
        }
    };

    return (
        <ModalOverlay isOpen={open} onClose={onClose} title="Give Feedback">
            <Box>
                <TextField
                    label="Title"
                    value={title}
                    onChange={handleTitleChange}
                    margin="normal"
                    fullWidth
                />
                <TextField
                    label="Feedback"
                    value={feedback}
                    onChange={handleFeedbackChange}
                    multiline
                    rows={7}
                    margin="normal"
                    fullWidth
                />
                <Button
                    variant="contained"
                    color="primary"
                    className="modal-save-button"
                    onClick={handleSubmitFeedback}
                >
                    Submit Feedback
                </Button>
            </Box>
        </ModalOverlay>
    );
};

export default Feedback;