import React from 'react';
import { Tooltip, Chip, Avatar } from '@mui/material';
import './UserChip.css';

const UserChip = ({ user, onDelete }) => {
    const displayName = user.name || user.firstName || user.email || 'Unknown';
    const avatarText = displayName.charAt(0).toUpperCase();
    const tooltipText = user.email || displayName;

    const chipProps = {
        avatar: <Avatar>{avatarText}</Avatar>,
        label: displayName,
        className: "user-chip"
    };

    if (onDelete) {
        chipProps.onDelete = onDelete;
    }

    return (
        <Tooltip title={tooltipText} arrow>
            <Chip {...chipProps} />
        </Tooltip>
    );
};

export default UserChip;