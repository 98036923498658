import React from 'react';
import {Typography, Grid, Box} from '@mui/material';
// import './Summary.css'; // Import the CSS file

const Summary = ({summaryData}) => {
    // if (!summaryData || !summaryData.summary) {
    //     return null; // Return null if summaryData or summaryData.summary is undefined or null
    // }

    if (!summaryData) {
        return null;
    }
    return (
        <Box className="grid-item">
            <Typography variant="h6">Meeting Summary</Typography>
            <Typography>{summaryData}</Typography>
        </Box>
    );
};

export default Summary;
