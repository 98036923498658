import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Typography, Button, Grid, Box} from '@mui/material';
import TeamCard from './TeamCard';
import './css/Teams.css';
import CreateTeamPanel from "./CreateTeamPanel";
import config from '../../config';
import DeleteModal from "../../components/deletemodal/DeleteModal";

const Teams = () => {
    const [teams, setTeams] = useState([]);
    const [isCreateTeamOpen, setIsCreateTeamOpen] = useState(false);
    const [selectedTeamId, setSelectedTeamId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [teamToDelete, setTeamToDelete] = useState(null);

    useEffect(() => {
        fetchTeams();
    }, []);

    const fetchTeams = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${config.backendUrlApiV1}/teams/user_teams/`);
            setTeams(response.data);
        } catch (error) {
            console.error('Error fetching teams:', error);
        } finally {
            setLoading(false);
        }
    };

    const closeCreateTeam = () => {
        setIsCreateTeamOpen(false);
        setSelectedTeamId(null);
    };

    const handleCreateTeam = () => {
        setSelectedTeamId(null);
        setIsCreateTeamOpen(true);
    };

    const handleTeamCardClick = (teamId) => {
        setSelectedTeamId(teamId);
        setIsCreateTeamOpen(true);
    };

    const handleDeleteClick = (team) => {
        setTeamToDelete(team);
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
        setTeamToDelete(null);
    };

    const deleteTeam = async () => {
        try {
            closeDeleteModal();
            await axios.delete(`${config.backendUrlApiV1}/teams/${teamToDelete.id}/`);
            await fetchTeams();
        } catch (error) {
            console.error('Error deleting team:', error);
        }
    };

    return (
        <Box className="teams-container">
            <Box className="teams-header">
                <Typography variant="h5" className="teams-title">Team Management</Typography>
                <Button variant="contained" color="primary" onClick={handleCreateTeam}>
                    Create New Team
                </Button>
            </Box>
            <Box className="teams-grid-container">
                {loading ? (
                    <Typography>Loading teams...</Typography>
                ) : teams.length === 0 ? (
                    <Typography variant="body1" className="no-teams-message">
                        Create some teams to track their behavioral dynamics, and monitor their psychological safety
                        over time.
                    </Typography>
                ) : (
                    <Grid container spacing={2} className="teams-grid">
                        {teams.map((team) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={team.id}>
                                <TeamCard
                                    team={team}
                                    onClick={() => handleTeamCardClick(team.id)}
                                    onDeleteClick={() => handleDeleteClick(team)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Box>
            <CreateTeamPanel
                isOpen={isCreateTeamOpen}
                onClose={closeCreateTeam}
                teamId={selectedTeamId}
                onSave={fetchTeams}
            />
            <DeleteModal
                isOpen={isDeleteModalOpen}
                onClose={closeDeleteModal}
                onDelete={deleteTeam}
                title="Delete Team"
                message={`Are you sure you want to delete the team "${teamToDelete?.name}"? This action cannot be undone.`}
            />
        </Box>
    );
};

export default Teams;