import React, {useState, useRef, useEffect} from 'react';
import axios from 'axios';
import {
    Box,
    Grid,
    Chip,
    Typography,
    Card,
    CardContent,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Tooltip,
    styled,
    tooltipClasses,
} from '@mui/material';
import Transcription from './transcript/Transcription';
import './Indicators.css';
import config from '../../config';
import IndicatorCard from '../../components/IndicatorCard';
import {trackInteraction} from "../../common/googleanalytics";

// Styled Tooltip component
const StyledTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(12),
    },
}));

const AnalysisSection = ({analysis, score, scoreBreakdown, scoreConfidence, improvementTips}) => (
    <Card variant="outlined" sx={{mb: 2}}>
        <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">Analysis</Typography>
                <Box display="flex" alignItems="center">
                    <StyledTooltip
                        title={
                            <>
                                <Typography variant="body2" sx={{fontWeight: 'bold'}}>Score Confidence</Typography>
                                <Typography variant="body2">{scoreConfidence}</Typography>
                                <Typography variant="body2" sx={{fontWeight: 'bold'}}>Score Breakdown</Typography>
                                <Typography variant="body2">{scoreBreakdown}</Typography>
                            </>
                        }
                        placement="top"
                    >
                        <Typography variant="h6" sx={{cursor: 'pointer'}}>
                            Score: {score} / 100
                        </Typography>
                    </StyledTooltip>
                </Box>
            </Box>
            <Typography variant="body1" paragraph>{analysis}</Typography>
            {improvementTips && (
                <Box mt={2}>
                    <Typography variant="h6" gutterBottom>Improvement Tips</Typography>
                    <Typography variant="body1" paragraph>{improvementTips}</Typography>
                </Box>
            )}
        </CardContent>
    </Card>
);

const StatementBox = ({statement, onClick}) => (
    <Box
        className="statement-box"
        onClick={() => onClick(statement)}
        sx={{cursor: 'pointer', '&:hover': {backgroundColor: 'action.hover'}}}
    >
        <Typography className="statement">{statement}</Typography>
    </Box>
);

const Indicators = ({indicatorsData, transcript}) => {
    const [selectedIndicator, setSelectedIndicator] = useState(null);
    const [view, setView] = useState('all');
    const containerRef = useRef(null);
    const [indicatorCards, setIndicatorCards] = useState({});

    const transcriptionRef = useRef(null);
    const [transcriptData, setTranscriptData] = useState(transcript);

    useEffect(() => {
        fetchIndicatorCards();
    }, []);

    const fetchIndicatorCards = async () => {
        try {
            const response = await axios.get(`${config.backendUrlApiV1}/indicator-cards`);
            setIndicatorCards(response.data);
        } catch (error) {
            console.error('Error fetching indicator cards:', error);
        }
    };

    const viewOptions = [
        'all',
        ...Object.keys(indicatorsData)
            .filter(key => key !== 'all')
            .sort((a, b) => a.localeCompare(b))
    ];

    const getFilteredData = () => {
        return view === 'all' ? indicatorsData.all : indicatorsData[view] || {};
    };

    const filteredData = getFilteredData();
    const llmIndicators = Object.keys(filteredData).filter(key =>
        !['ratio', 'segments', 'affirmations', 'sentiment', 'interactionMap', 'llmIndicators', 'userId'].includes(key)
    );
    const speakerName = view === 'all' ? 'All Speakers' : filteredData.speakerName || `${view}`;

    useEffect(() => {
        if (llmIndicators.length > 0 && !selectedIndicator) {
            setSelectedIndicator(llmIndicators[0]);
        }
    }, [llmIndicators, selectedIndicator]);

    const handleChipClick = (indicator) => {
        setSelectedIndicator(indicator);
        trackInteraction.buttonClick('indicator_'+indicator)
    };

    const handleStatementClick = (statement) => {
        if (transcriptionRef.current) {
            transcriptionRef.current.highlightAndScrollTo(statement);
        }
    };

    const formatKeyFromCamelCaseToNormal = (key) => {
        return key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
    };

    const selectedData = selectedIndicator ? filteredData[selectedIndicator] : null;

    if (!selectedData) {
        return <Typography>No indicator data available</Typography>;
    }

    const meetingTitle = transcript?.metaData?.meetingName || 'Unknown Meeting Name';


    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center'}}>
                    <Typography variant="h3" sx={{ flexGrow: 1 }}>
                        {meetingTitle}
                    </Typography>
                    <FormControl className="view-select">
                        <InputLabel id="view-select-label">View</InputLabel>
                        <Select
                            labelId="view-select-label"
                            id="view-select"
                            value={view}
                            label="View"
                            onChange={(e) => setView(e.target.value)}
                        >
                            {viewOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option === 'all' ? 'All' : `${option}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={12} md={8}>
                <Box className="grid-item grid-item-transcription" ref={containerRef}>
                    <Typography variant="h4" gutterBottom sx={{mb: 3}}>
                        {speakerName}
                    </Typography>

                    <Box className="chip-container" sx={{mb: 2}}>
                        {llmIndicators.map((key) => (
                            <Chip
                                key={key}
                                label={formatKeyFromCamelCaseToNormal(key)}
                                onClick={() => handleChipClick(key)}
                                color={selectedIndicator === key ? 'primary' : 'default'}
                                className="indicator-chip"
                                sx={{mr: 1, mb: 1}}
                            />
                        ))}
                    </Box>

                    <Box display="flex" alignItems="center" sx={{mb: 2}}>
                        <Typography variant="h5" sx={{mr: 1}}>
                            Indicator {formatKeyFromCamelCaseToNormal(selectedIndicator)}
                        </Typography>
                        <IndicatorCard
                            indicator={selectedIndicator}
                            indicatorCards={indicatorCards}
                        />
                    </Box>

                    <AnalysisSection
                        analysis={selectedData.analysis}
                        improvementTips={selectedData.improvementTips}
                        score={selectedData.score}
                        scoreBreakdown={selectedData.scoreBreakdown}
                        scoreConfidence={selectedData.scoreConfidence}
                    />

                    {Object.entries(selectedData).map(([subIndicator, value]) => (
                        !['speakerName', 'speakerId', 'score', 'analysis', 'scoreBreakdown', 'scoreConfidence', 'improvementTips', 'userId'].includes(subIndicator) &&
                        value && (
                            <Card key={subIndicator} variant="outlined" sx={{mb: 2}}>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        {formatKeyFromCamelCaseToNormal(subIndicator)}
                                    </Typography>
                                    {Array.isArray(value) ? (
                                        value.map((statement, index) => (
                                            <StatementBox
                                                key={index}
                                                statement={statement}
                                                onClick={handleStatementClick}
                                            />
                                        ))
                                    ) : (
                                        <Typography variant="body1">
                                            {value}
                                        </Typography>
                                    )}
                                </CardContent>
                            </Card>
                        )
                    ))}
                </Box>
            </Grid>
            <Grid item xs={12} md={4}>
                <Box className="grid-item grid-item-transcription">
                    <Transcription data={transcriptData} setData={setTranscriptData} ref={transcriptionRef}/>
                </Box>
            </Grid>
        </Grid>
    );
};

export default Indicators;
